<template>
  <div>

    <el-container>
      <el-header style="background-color:#FFF;">
        <el-row class="pt-2 pb-2">
          <el-col :span="10" style="line-height:50px">
            <span v-if="currentPatient"><b>{{fullInfo}}</b></span>
            <span v-else>&nbsp;</span>
          </el-col>
          <el-col :span="10">

          </el-col>
          <el-col :span="2" style="line-height:50px; text-align:right">
            <el-button @click="navigateToStart" class="mr-3 pl-3 pr-3 pr-3 pt-2 pb-2" type="warning" icon="el-icon-s-home" ></el-button>
          </el-col>
          <el-col :span="2" class="text-right align-content-end">
            <main-menu></main-menu>
          </el-col>
        </el-row>
      </el-header>
      <div class="w-100" style="background-color:#f39200; height:8px;"></div>
      <el-main>
        <el-row>
          <el-col :span="24">

            <el-card class="box-card">

              <router-view></router-view>

            </el-card>

          </el-col>
        </el-row>
      </el-main>
    </el-container>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainMenu from "@/components/parts/MainMenu";

export default {
  name: "Studies",
  props: [],
  components: {MainMenu},
  data() {
    return {
    }
  },
  mounted: async function () {
    await this.$store.dispatch('patients/setCurrentPatient', this.$route.params.id)
    document.title = this.fullInfo
  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
  },
  methods: {
    navigateToStart() {
      this.$router.push({ name: 'study_dashboard' } )
    }
  }
}
</script>
<style scoped>
</style>
